import React, { PureComponent, createRef } from 'react';
import Carousel from 'react-elastic-carousel';
import Dots from './../../../public/home/dots.webp';


class HomeTestimonials extends PureComponent {
    constructor(props) {
        super(props);
        this.catouselRefMD = createRef();
        this.catouselRefLG = createRef();
        this.catouselRefSM = createRef();
    }
    testimonialCard = (testimonial, index) => (
        <div className="testimonial-card mb-4 d-flex flex-column" key={index}>
            <div className="testimonial-description d-flex">{testimonial.testimonial_text}</div>
            <div className="d-flex flex-column flex-grow-1 justify-content-end pt-3 pt-lg-5 pt-md-5 pt-sm-3">
                <div className="testimonial-user">{testimonial.person_name}</div>
                <div className="testimonial-designation pt-2">{testimonial.affiliation}</div>
            </div>
        </div>
    )

    onNextStart = (currentItem, nextItem, element) => {
        if (currentItem.index === nextItem.index) {
            element.current.goTo(0);
        }
    }

    onPrevStart = (currentItem, nextItem, element) => {
        if (currentItem.index === nextItem.index) {
            element.current.goTo(this.props.data.length);
        }
    }

    render() {
        return (
            <>
                <div className="testimonial-container py-md-3 px-md-5 py-5 px-2">
                    <div className="row g-0">
                        <div className="col py-md-5">
                            <h3 className="testimonial-title text-center">
                                What Users Say About <span className="theme-text">KonfHub</span>?
                            </h3>
                            <div className="testimonial-sub-title text-center">
                                Don’t take our word for it. Trust our users
                            </div>
                        </div>
                    </div>
                    <div className="row main-container g-0 pb-3 py-md-0 pb-md-5">
                        <div className="col d-none d-md-block d-lg-none">
                            <Carousel
                                ref={this.catouselRefMD}
                                itemsToShow={1}
                                itemPadding={[0, 20, 0, 20]}
                                onNextStart={(currentItem, nextItem) => this.onNextStart(currentItem, nextItem, this.catouselRefMD)}
                                onPrevStart={(currentItem, nextItem) => this.onPrevStart(currentItem, nextItem, this.catouselRefMD)}
                                disableArrowsOnEnd={false}
                                disableArrowsOnStart={false}
                            >
                                {
                                    this.props.data.map(this.testimonialCard)
                                }
                            </Carousel>
                        </div>
                        <div className="col d-none d-md-none d-lg-block">
                            <Carousel
                                ref={this.catouselRefLG}
                                itemsToShow={2}
                                itemPadding={[0, 20, 0, 20]}
                                onNextStart={(currentItem, nextItem) => this.onNextStart(currentItem, nextItem, this.catouselRefLG)}
                                onPrevStart={(currentItem, nextItem) => this.onPrevStart(currentItem, nextItem, this.catouselRefLG)}
                                disableArrowsOnEnd={false}
                                disableArrowsOnStart={false}
                            >
                                {
                                    this.props.data.map(this.testimonialCard)
                                }
                            </Carousel>
                        </div>
                        <div className="col d-block d-md-none">
                            <Carousel
                                ref={this.catouselRefSM}
                                itemsToShow={1}
                                onNextStart={(currentItem, nextItem) => this.onNextStart(currentItem, nextItem, this.catouselRefSM)}
                                onPrevStart={(currentItem, nextItem) => this.onPrevStart(currentItem, nextItem, this.catouselRefSM)}
                                disableArrowsOnEnd={false}
                                disableArrowsOnStart={false}
                            >
                                {
                                    this.props.data.map(this.testimonialCard)
                                }
                            </Carousel>
                        </div>
                    </div>
                </div>
                <style>{`
                    .testimonial-container {
                        background: #ffffff;
                    }

                    .testimonial-container .testimonial-title {
                        color: #572148;
                        font-weight: 700;
                        font-size: 50px;
                        font-family: "Nunito";
                    }

                    .testimonial-container .testimonial-title .theme-text {
                        color: #FB5850;
                    }

                    .testimonial-container .testimonial-sub-title {
                        font-family: 'Nunito';
                        font-weight: 400;
                        font-size: 22px;
                        color: #572148;
                    }

                    .main-container{
                        padding-top: 40px !important;
                        background-image: url(${Dots});
                        background-position: right 40px top 0px;
                        background-repeat: no-repeat;
                    }

                    .testimonial-container .testimonial-card {
                        padding: 24px;
                        background: #F6F9FC;
                        border: 1.5px solid rgba(87, 33, 72, 0.75);
                        border-radius: 10px;
                    }

                    .testimonial-container .testimonial-card .testimonial-description {
                        font-family: 'Nunito';
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 24px;
                        color: #572148;
                    }

                    .testimonial-container .testimonial-card .testimonial-user {
                        font-family: 'Nunito';
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 20px;
                        color: #572148;
                    }

                    .testimonial-container .testimonial-card .testimonial-designation {
                        font-family: 'Nunito';
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 20px;
                        color: #572158;
                    }

                    /* SM screens */
                    @media only screen and (min-width: 480px) and (max-width: 768px) {
                        .testimonial-container .testimonial-title {
                            font-size: 30px;
                        }
                        .testimonial-container .testimonial-card .testimonial-description{
                            font-size: 14px;
                        }
                        .testimonial-container .testimonial-card .testimonial-user{
                            font-size: 16px;
                        }
                        .testimonial-container .testimonial-card .testimonial-description{
                            font-size: 14px;
                        }

                        .testimonial-container .testimonial-sub-title {
                            font-size: 16px;
                        }
                        .description {
                            font-size: 16px !important;
                        }
                    }

                    /* XS screens */
                    @media only screen and (max-width: 480px) {
                        .testimonial-container .testimonial-title {
                            font-size: 30px;
                        }

                    .testimonial-container .testimonial-card .testimonial-description {
                        font-size: 14px !important;

                    }
                    .testimonial-container .testimonial-card .testimonial-user{
                        font-size: 16px;
                    }
                    .testimonial-container .testimonial-card .testimonial-description{
                        font-size: 14px;
                    }

                    .testimonial-container .testimonial-sub-title {
                        font-size: 16px;
                    }
                    .description {
                        font-size: 16px !important;
                    }
                    }
                `}</style>
            </>
        )
    }
}

export default HomeTestimonials;
